/* eslint-disable import/no-duplicates */
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Controller } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Controller]);

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

$(document).ready(() => {
  let wWidth = $(window).width();
  let navState = false;
  const $header = $('.page-header');
  const $popup = $('.popup');
  let isObserver = true;
  let isTouch = false;
  let observer;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (
    !('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('isIntersecting' in window.IntersectionObserverEntry.prototype)
  ) {
    isObserver = false;
    $('html').removeClass('is-observer');
  }

  if (isObserver) {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '0px 0px -15% 0px' }
    );
  }

  function isTouchDevice() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = (query) => {
      return window.matchMedia(query).matches;
    };

    if (
      'ontouchstart' in window ||
      // eslint-disable-next-line no-undef
      (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
      ''
    );
    return mq(query);
  }

  if (isTouchDevice()) {
    isTouch = true;
    $('html').addClass('is-touch');
  }

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate, ...args) {
    let timeout;
    return function () {
      const context = this;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function disableScrolling() {
    if ($(document).height() > $(window).height()) {
      const scrollTop = $('html').scrollTop()
        ? $('html').scrollTop()
        : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
      $('html').addClass('disable-scrolling').css('top', -scrollTop);
    }
  }

  function enableScrolling() {
    const scrollTop = parseInt($('html').css('top'), 10);
    $('html').removeClass('disable-scrolling');
    $('html,body').scrollTop(-scrollTop);
  }

  function bindEvents() {
    $('.page-header__hamburger').on('click', () => {
      if (navState) {
        $header.removeClass('is-opened');
        enableScrolling();
      } else {
        $header.addClass('is-opened');
        disableScrolling();
      }

      navState = !navState;
    });

    // FOCUS STYLING
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', () => {
      document.body.classList.remove('is-tab');
    });

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', (event) => {
      if (event.key === 'Tab') {
        document.body.classList.add('is-tab');
      }
    });

    // dates
    $('.js-dates').on('click', () => {
      gsap.to(window, {
        scrollTo: { y: '#dates', offsetY: 150 },
        duration: 1,
      });
    });

    // legal links
    $('.legals__links a').on('click', (e) => {
      e.preventDefault();
      const { hash } = e.currentTarget;
      const el = document.getElementById(hash.substring(1));

      if (el) {
        gsap.to(window, {
          scrollTo: {
            y: hash,
            offsetY: 150,
          },
          duration: 1,
          onComplete: () => {
            if (window.history.pushState) {
              window.history.pushState(null, null, hash);
            } else {
              window.location.hash = hash;
            }
          },
        });
      }
    });

    // Enroll now
    // $('.js-enroll').on('click', (e) => {
    //   const url = $(e.currentTarget).data('url');

    //   if (isSafari) {
    //     window.open(url, '_blank');
    //   } else {
    //     $popup.addClass('is-opened');

    //     setTimeout(() => {
    //       $popup.removeClass('is-opened');
    //       window.open(url, '_blank');
    //     }, 3000);
    //   }
    // });

    if (window.location.hash === '#module-7') {
      $('#module-7')
        .addClass('is-opened')
        .children('.accordion__content')
        .slideDown();

      gsap.to(window, {
        scrollTo: {
          y: '#module-7',
          offsetY: 250,
        },
        duration: 1,
      });
    }

    if (window.location.hash === '#modules') {
      gsap.to(window, {
        scrollTo: {
          y: '#modules',
          offsetY: 250,
        },
        duration: 1,
      });
    }
  }

  function initHero() {
    const $container = $('.hero--home');

    if (!$container.length) return;
    const $logo = $header.find('.page-header__logo');
    const $logoSign = $logo.find('>span');
    const $scroll = $container.find('.hero__scroll');

    ScrollTrigger.saveStyles([$logoSign[0], $logo[0]]);

    ScrollTrigger.matchMedia({
      // eslint-disable-next-line func-names
      '(max-width: 767px)': function () {
        ScrollTrigger.create({
          trigger: $container[0],
          start: 'top top',
          end: 'bottom 50%',
          onEnterBack: () => {
            $header.removeClass('is-logo');
          },
          onLeave: () => [$header.addClass('is-logo')],
        });
      },
      // eslint-disable-next-line func-names
      '(min-width: 768px)': function () {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: $container[0],
              start: 'top top',
              end: '+=100%',
              scrub: true,
              pin: true,
            },
            ease: 'none',
          })
          .to($logoSign[0], {
            x: 0,
            y: '-50%',
            scale: 1,
            duration: 1,
          })
          .to(
            $logo[0],
            {
              x: () => {
                return (
                  (-1 * ($logoSign.width() + 15 + $logo.width())) / 2 +
                  $logo.width() / 2
                );
              },
              duration: 0.5,
            },
            0.5
          );
      },
    });

    $scroll.on('click', () => {
      gsap.to(window, {
        scrollTo: window.innerHeight,
        duration: 1,
      });
    });
  }

  function initMarquee() {
    const $blocks = $('.marquee');

    if (!$blocks.length) return;

    $blocks.each((i, el) => {
      const $block = $(el);
      const $line = $block.children('.marquee__line');

      gsap.to($line[0], {
        x: '-100vw',
        scrollTrigger: {
          trigger: $block[0],
          start: 'top bottom',
          end: 'bottom top',
          scrub: 0.5,
          ease: 'none',
        },
      });
    });
  }

  function initAccordion() {
    const $blocks = $('.accordion');

    if (!$blocks.length) return;

    $blocks.each((i, el) => {
      const $block = $(el);
      const $accordion = $block.find('.accordion__accordion');
      const $rows = $accordion.find('li');
      const $btns = $rows.children('.accordion__btn');
      const $contents = $rows.children('.accordion__content');

      $btns.on('mouseover', (e) => {
        const $curRow = $(e.currentTarget).parent();

        if (!isTouch) {
          if (!$curRow.hasClass('is-opened')) {
            $rows.removeClass('is-opened');
            $contents.slideUp();

            $curRow
              .addClass('is-opened')
              .children('.accordion__content')
              .slideDown();
          }
        }
      });

      $btns.on('click', (e) => {
        const $curRow = $(e.currentTarget).parent();

        if (isTouch) {
          if (!$curRow.hasClass('is-opened')) {
            $rows.removeClass('is-opened');
            $contents.slideUp();

            $curRow
              .addClass('is-opened')
              .children('.accordion__content')
              .slideDown();
          } else {
            $curRow
              .removeClass('is-opened')
              .children('.accordion__content')
              .slideUp();
          }
        }
      });

      if ($block.find('#module-7').length) {
        $('.js-module-scroll').on('click', (e) => {
          e.preventDefault();

          gsap.to(window, {
            scrollTo: {
              y: '#module-7',
              offsetY: 250,
            },
            duration: 1,
          });

          // hide active rows
          $rows.removeClass('is-opened');
          $contents.slideUp();

          // show ronw-7
          $('#module-7')
            .addClass('is-opened')
            .children('.accordion__content')
            .slideDown();
        });
      }
    });
  }

  function initGallery() {
    const $blocks = $('.gallery');

    if (!$blocks.length) return;

    $blocks.each((i, el) => {
      const $block = $(el);
      const $gallery = $block.children('.gallery__inner');
      const $prev = $gallery.children('.gallery__prev');
      const $next = $gallery.children('.gallery__next');

      // eslint-disable-next-line no-new
      new Swiper($gallery[0], {
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        speed: 600,
        navigation: {
          nextEl: $next[0],
          prevEl: $prev[0],
        },
        pagination: {
          el: $block.find('.swiper-pagination')[0],
          type: 'bullets',
        },
      });
    });
  }

  function initTestimonials() {
    const $blocks = $('.testimonials');

    if (!$blocks.length) return;

    $blocks.each((i, el) => {
      const $block = $(el);
      const $gallery = $block.children('.testimonials__gallery');
      const $testimonials = $block.find('.testimonials__quotes');

      const gallery = new Swiper($gallery[0], {
        loop: true,
        speed: 600,
        pagination: {
          el: $gallery.find('.swiper-pagination')[0],
          type: 'bullets',
        },
      });

      const testimonials = new Swiper($testimonials[0], {
        loop: true,
        speed: 600,
        navigation: {
          nextEl: $block.find('.testimonials__next')[0],
          prevEl: $block.find('.testimonials__prev')[0],
        },
        spaceBetween: 30,
        pagination: {
          el: $testimonials.siblings('.swiper-pagination')[0],
          type: 'bullets',
        },
        controller: {
          control: gallery,
        },
      });

      gallery.controller.control = testimonials;
    });
  }

  // function initDates() {
  //   const $dates = $('.dates');

  //   if (!$dates.length) return;
  //   const $rows = $dates.find('.dates__row');
  //   const $btns = $rows.find('.date-btn__arrow');
  //   const $content = $rows.find('.dates__content');
  //   const $toggleBtns = $content.find('.dates__toggle');
  //   const $scheduleToggleBtns = $content.find('.dates__schedule-toggle');
  //   const $plans = $content.find('.dates__plan');

  //   // open rows
  //   $btns.on('click', (e) => {
  //     const $cur = $(e.currentTarget).closest('.dates__row');

  //     if (!$cur.hasClass('is-opened')) {
  //       $rows.removeClass('is-opened is-plan');
  //       $content.slideUp();
  //       $plans.slideUp();
  //       $cur.addClass('is-opened').children('.dates__content').slideDown();
  //     } else {
  //       $cur
  //         .removeClass('is-opened is-plan')
  //         .children('.dates__content')
  //         .slideUp()
  //         .find('.dates__plan')
  //         .slideUp();
  //     }
  //   });

  //   // toggle plans
  //   $toggleBtns.on('click', (e) => {
  //     const $row = $(e.currentTarget).closest('.dates__row');

  //     $row.removeClass('is-schedule').find('.dates__schedule').slideUp();
  //     $row.toggleClass('is-plan').find('.dates__plan').slideToggle();
  //   });

  //   // schedule days
  //   $scheduleToggleBtns.on('click', (e) => {
  //     const $row = $(e.currentTarget).closest('.dates__row');

  //     $row.removeClass('is-plan').find('.dates__plan').slideUp();
  //     $row.toggleClass('is-schedule').find('.dates__schedule').slideToggle();
  //   });
  // }

  function initMediaTextCarousel() {
    const $blocks = $('.media-text');

    if (!$blocks.length) return;
    $blocks.each((i, el) => {
      const $block = $(el);
      const $carousel = $block.find('.media-text__carousel');

      // eslint-disable-next-line no-new
      new Swiper($carousel[0], {
        speed: 600,
        loop: true,
        pagination: {
          el: $carousel.find('.swiper-pagination')[0],
          type: 'bullets',
        },
      });
    });
  }

  function initSlider() {
    const $blocks = $('.slider');

    if (!$blocks.length) return;
    $blocks.each((i, el) => {
      const $block = $(el);
      const $carousel = $block.find('.slider__inner');

      // eslint-disable-next-line no-new
      new Swiper($carousel[0], {
        speed: 600,
        loop: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        loopedSlides: 3,
        navigation: {
          nextEl: $block.find('.slider__next')[0],
          prevEl: $block.find('.slider__prev')[0],
        },
        breakpoints: {
          1024: {
            centeredSlides: false,
          },
        },
      });
    });
  }

  function initSplitScreen() {
    const $blocks = $('.split');

    if (!$blocks.length) return;
    $blocks.each((i, el) => {
      const $block = $(el);
      const $cols = $block.find('.split__col');

      gsap
        .timeline({
          scrollTrigger: {
            trigger: $block[0],
            start: 'top top',
            end: '+=150%',
            pin: true,
            scrub: true,
          },
        })
        .to({}, { duration: 0.3 })
        .to([...$cols], {
          x: 0,
          y: 0,
          duration: 1,
        })
        .to({}, { duration: 0.3 });
    });
  }

  const doneResizing = debounce(() => {
    const width = $(window).width();

    if (wWidth !== width) {
      wWidth = width;
      // wHeight = $(window).height();

      if (wWidth > 767 && navState) {
        navState = !navState;
        $header.removeClass('is-opened');
        enableScrolling();
      }
    }
  }, 500);

  /* FUNCTION CALLS */
  /* ============= */
  bindEvents();
  initHero();
  initSplitScreen();
  initMarquee();
  initAccordion();
  initGallery();
  initTestimonials();
  // initDates();
  initMediaTextCarousel();
  initSlider();

  if (isObserver) {
    $('.js-visibility, .js-visibility-content>*').each((i, el) => {
      observer.observe(el);
    });
  }

  $(window).on('resize', doneResizing);
});
